import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { Modal, Box, TextField, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StarIcon from "@mui/icons-material/Star";
import * as UserService from "../../services/UserServices";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import * as promptEngineService from "../../services/PromptEngineService";
import * as favoriteService from "../../services/FavoriteServices";

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "black",
    border: "2px solid darkgreen",
    backgroundColor: "white",
    borderRadius: "10px",
    fontWeight: 500,
    maxWidth: 300,
  },
})(Tooltip);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  minHeight: 400,
  bgcolor: "background.paper",
  border: "2px",
  boxShadow: 24,
  borderRadius: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ minWidth: "100%" }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ChatOptionsComponent(props) {
  const [sessionId, setSessionId] = useState();
  const [updatedPrompt, setUpdatedPrompt] = useState();
  // Assuming message is received as a prop

  React.useEffect(() => {}, []);

  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parts, setParts] = useState([]);
  const [extractedField, setExtractedField] = useState([]);
  const [isCustomisedPrompt, setIsCustomisedPrompt] = useState(false);
  const [value, setValue] = React.useState(0);
  const [accessToken, setAccessToken] = React.useState("");
  const [favoriteResponse, setFavoriteResponse] = React.useState([]);
  const [documentType, setDocumentType] = React.useState([]);
  const [catagoryType, setCatagoryType] = React.useState([]);
  const [catagoryId, setCatagoryId] = React.useState("");
  const [promptList, setPromptList] = React.useState([]);
  const [checkedItem, setCheckedItem] = useState([]);
  const [deleteFavorite, setDeleteFavorite] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (item) => (event) => {
    console.log("Selected Value : ", item);
    const isChecked = event.target.checked;

    if (isChecked && item !== checkedItem) {
      setCheckedItem(item);
      const updatedSelectedQuestions = selectedQuestions.filter(
        (q) => q !== item
      );
      setSelectedQuestions(updatedSelectedQuestions.concat(item));
      fetchQuestionbyCategory(item);
    } else if (!isChecked && item === checkedItem) {
      setCheckedItem(null); // Uncheck the checkbox

      setSelectedQuestions([]);
      setPromptList([]);
    }
  };

  const handleRadioChange = (item) => (event) => {
    const isChecked = event.target.checked;

    if (isChecked && item !== checkedItem) {
      setCheckedItem(item);
      const updatedSelectedQuestions = selectedQuestions.filter(
        (q) => q !== item
      );
      setSelectedQuestions(updatedSelectedQuestions.concat(item));
      fetchQuestionbyCategory(item);
    } else if (!isChecked && item === checkedItem) {
      setCheckedItem(null); // Uncheck the checkbox

      setSelectedQuestions([]);
      setPromptList([]);
    }
  };
  const fetchQuestionbyCategory = async (item) => {
    // Make API call based on the index
    console.log("Index : ", item);

    const accessToken = localStorage.getItem("accessToken");
    try {
      if (item !== null) {
        promptEngineService
          .fetchQuestionbyCategory(item, catagoryId)
          .then((messages) => {
            //console.log("Category type messages: ", messages);
            if (messages !== undefined) {
              //setCatagoryType(messages.categories);
              setPromptList(messages["questions"]);
            }
          });
      }
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setIsModalOpen(true);
    setUpdatedPrompt(question[0]);
    // Splitting the question into parts based on placeholders
    let pt = question[0].split(/{([{}]+)}/);
    console.log("Parts : ", pt);

    let matches = question[0].match(/\{([^{}]+)\}/g);
    let extractedValues = [];
    if (matches) {
      extractedValues = matches.map((match) =>
        match.replace(/\{|\}/g, "").trim()
      );
      console.log("Extracted values:", extractedValues);
    } else {
      console.log("No matches found.");
    }

    // Storing the parts in state
    setParts(pt);
    // Storing the extracted values in state
    setExtractedField(extractedValues);
  };

  const handleInputChange = (event, index, question) => {
    const updatedField = event.target.value;

    const placeholder = extractedField[index];
    console.log("Placeholder:", placeholder);

    // Escape special characters in the placeholder for use in the regular expression
    const escapedPlaceholder = placeholder.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );

    // Create a regular expression pattern to match the placeholder
    const placeholderPattern = new RegExp(`\\{${escapedPlaceholder}\\}`, "g");
    const updatedQuestion = question.replace(placeholderPattern, updatedField);

    // Update the state with the updated question and extracted field
    setUpdatedPrompt(updatedQuestion);
    const updatedExtractedField = [...extractedField];
    updatedExtractedField[index] = updatedField;
    setExtractedField(updatedExtractedField);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleIsCustomised = () => {
    setIsCustomisedPrompt(!isCustomisedPrompt);
  };

  useEffect(() => {
    if (value === 1) {
      // Check if the "Favorites" tab is active
      const storedAccessToken = localStorage.getItem("accessToken");
      if (storedAccessToken) {
        setAccessToken(storedAccessToken);
      }
      fetchUserFavoritesByAccessToken(storedAccessToken);
    }
  }, [value]);
  useEffect(() => {
    // Check if the "Favorites" tab is active
    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);

      fetchUserFavoritesByAccessToken(storedAccessToken);
      //props.setUpdateFavorites(false);
    }
  }, [props.updateDeleteFavorites]);

  useEffect(() => {
    if (value === 1) {
      // Check if the "Favorites" tab is active
      const storedAccessToken = localStorage.getItem("accessToken");
      if (storedAccessToken) {
        setAccessToken(storedAccessToken);
      }
      fetchUserFavoritesByAccessToken(storedAccessToken);
    }
  }, [deleteFavorite]);

  useEffect(() => {
    if (value === 0) {
      // Check if the "Favorites" tab is active
      const storedAccessToken = localStorage.getItem("accessToken");
      if (storedAccessToken) {
        setAccessToken(storedAccessToken);
      }

      fetchDocumentType(storedAccessToken);
    }
  }, []);

  const handleChangeA = async (index, panelIndex) => {
    // Make API call based on the index
    const accessToken = localStorage.getItem("accessToken");

    promptEngineService.fetchCategoryType(index).then((messages) => {
      //console.log("Category type messages: ", messages);
      if (messages !== undefined) {
        setCatagoryType(messages.categories);
      }
    });

    setCatagoryId(index);
    setExpanded((prevExpanded) =>
      prevExpanded === panelIndex ? false : panelIndex
    );
    setPromptList([]);
  };

  const fetchDocumentType = async (accessToken) => {
    try {
      // Send user message to the backend
      let docType = "";
      promptEngineService.fetchDocumentType(accessToken).then((messages) => {
        //console.log("Doc type messages: ", messages);
        if (messages !== undefined) {
          setDocumentType(messages.document_types);
        }
      });
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error, e.g., show an error message to the user
    }
  };

  const fetchUserFavoritesByAccessToken = async (accessToken) => {
    try {
      // Send user message to the backend

      favoriteService
        .fetchUserFavoritesByAccessToken(accessToken)
        .then((messages) => {
          //console.log("Doc type messages: ", messages);
          if (messages !== undefined) {
            //console.log("Favorite response : ", messages);
            setFavoriteResponse(messages.messages);
          }
        });
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error, e.g., show an error message to the user
    }
  };
  const handleDeletItem = async (chat_id) => {
    //console.log("Chat id to be deleted : ", chat_id);
    try {
      favoriteService.handleFavoriteDelete(chat_id).then((messages) => {
        console.log("Delete chat ID: ", messages);
        if (messages !== undefined) {
          setDeleteFavorite(!deleteFavorite);
          props.setUpdateFavorites(chat_id);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleItemClick = (item) => {
    // Handle the click event, such as navigating to a different page or displaying more information about the item.

    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }

    props.setSelectedPrompt(item);
    //navigate("/");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, item) => {
    //console.log("Mouse over  item:", item);
    setPopoverContent(item);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleItemClickFromPrompt = (item) => {
    // Handle the click event, such as navigating to a different page or displaying more information about the item.
    //console.log("Clicked item:", item);

    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }

    props.setSelectedPrompt(item.name);
    //navigate("/");
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          sx={{ padding: "0px !important", minWidth: "100%" }}
          TabIndicatorProps={{
            style: { background: "white" },
          }}
        >
          <Tab
            icon={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <FilterListIcon sx={{ fontSize: 15, mr: 1 }} />
                <Typography
                  style={{
                    fontSize: 10,
                    fontWeight: 550,
                  }}
                  gutterBottom
                >
                  Prompt Engine
                </Typography>
              </Grid>
            }
            aria-label="phone"
            sx={{
              borderBottom: "1px solid rgb(1, 105, 105)",
              "&.Mui-selected": {
                background: "darkgreen",
                color: "white",
              },
              color: "#1f3b52",
            }}
          />

          <Tab
            icon={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <StarIcon sx={{ fontSize: 15, mr: 1 }} />
                <Typography
                  style={{
                    fontSize: 10,
                    fontWeight: 550,
                  }}
                >
                  Favorites
                </Typography>
              </Grid>
            }
            aria-label="phone"
            sx={{
              borderBottom: "1px solid rgb(1, 105, 105)",
              "&.Mui-selected": {
                background: "darkgreen",
                color: "white",
                fontWeight: 600,
              },
              color: "#1f3b52",
            }}
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <List>
            {documentType.length > 0 ? (
              documentType.map((type, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={() => handleChangeA(type.id, `panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      fontSize: 13,
                      fontWeight: 600,
                      color: "#095353",
                    }}
                  >
                    {type.name}
                  </AccordionSummary>
                  <AccordionDetails sx={{ overflow: "hidden" }}>
                    <List
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <FormGroup>
                        <Grid container>
                          <>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: 13,
                                fontWeight: 600,
                                color: "#095353",
                                mt: 2,
                              }}
                            >
                              Catagories Of Prompt:
                            </Typography>
                            <Divider sx={{ width: "100%", mb: 1 }} />
                          </>

                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ ml: 0.2 }}
                          >
                            {catagoryType.length > 0 ? (
                              catagoryType.map((item, itemIndex) => (
                                <FormControlLabel
                                  key={item.name}
                                  control={
                                    <Radio
                                      checked={checkedItem === item}
                                      onChange={handleRadioChange(item)}
                                    />
                                  }
                                  label={item.name}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 13,
                                    },
                                    "& .MuiTypography-body1": {
                                      fontSize: 13,
                                      pr: "50px",
                                    },
                                  }}
                                />
                              ))
                            ) : (
                              <Typography
                                variant="caption"
                                sx={{ p: 2, fontWeight: 600, color: "grey" }}
                              >
                                No categories available
                              </Typography>
                            )}
                          </Grid>

                          {promptList.length > 0 ? (
                            <>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: 13,
                                  fontWeight: 600,
                                  color: "#095353",
                                  mt: 2,
                                }}
                              >
                                Suggested Prompts:
                              </Typography>
                              <Divider sx={{ width: "100%" }} />
                            </>
                          ) : null}
                          <List>
                            {catagoryType.length > 0 ? (
                              promptList.length > 0 ? (
                                promptList.map((item, index) => (
                                  <BlueOnGreenTooltip
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    title={item.name}
                                    arrow
                                  >
                                    <ListItemButton
                                      sx={{ pl: 0 }}
                                      key={index}
                                      onClick={() =>
                                        handleItemClickFromPrompt(item)
                                      }
                                    >
                                      <ListItemText>
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            fontSize: 13,
                                          }}
                                        >
                                          {item.name}
                                        </Typography>
                                      </ListItemText>
                                    </ListItemButton>
                                  </BlueOnGreenTooltip>
                                ))
                              ) : (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    p: 2,
                                    fontWeight: 600,
                                    color: "#1f3b52",
                                  }}
                                >
                                  No prompts available
                                </Typography>
                              )
                            ) : (
                              <Typography
                                variant="caption"
                                sx={{ p: 2, fontWeight: 600, color: "#1f3b52" }}
                              >
                                No category selected
                              </Typography>
                            )}
                          </List>
                        </Grid>
                      </FormGroup>
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography
                variant="caption"
                sx={{ p: 2, fontWeight: 600, color: "#1f3b52" }}
                noWrap
              >
                No Suggested Prompts
              </Typography>
            )}
          </List>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <List>
            {favoriteResponse.length > 0 ? (
              favoriteResponse.map((item, index) => (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{
                      borderBottom: "2px solid lightgrey",
                    }}
                  >
                    <Grid item md={8} xl={10}>
                      <ListItemButton
                        key={index}
                        onClick={() => handleItemClick(item["message"])}
                      >
                        <BlueOnGreenTooltip
                          placement="left"
                          TransitionComponent={Zoom}
                          title={popoverContent}
                          arrow
                        >
                          <ListItemText
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(event) =>
                              handlePopoverOpen(event, item["message"])
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 550,
                                fontSize: "0.75rem",
                                color: "blacksmoke",
                              }}
                            >
                              {item["message"]}
                            </Typography>
                          </ListItemText>
                        </BlueOnGreenTooltip>
                      </ListItemButton>
                    </Grid>

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleDeletItem(item["id"])}
                    >
                      <RemoveCircleIcon
                        sx={{ color: "#966994" }}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </Grid>
                </>
              ))
            ) : (
              <Typography
                variant="caption"
                sx={{ p: 2, fontWeight: 600, color: "#1f3b52" }}
              >
                No favorites added
              </Typography>
            )}
          </List>
        </CustomTabPanel>
      </Grid>
    </>
  );
}
export default ChatOptionsComponent;

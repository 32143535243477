import * as Global from "./Global";

export const fetchHistoryMessageByAccessToken = async (accessToken) => {
  try {
    // Send user message to the backend
    //console.log("Access token: ", accessToken);
    const response = await fetch(
      "https://miraserver.quantino.in/mira/api/user-chats/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      console.error("Error code:", response.status);
      throw new Error("Failed to submit data");
      return false;
    }

    const responseData = await response.json();
    //console.log("responseData: ", responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    return false;
  }
};

export const fetchHistoryBySessionId = async (item) => {
  try {
    //console.log("Session id : ", item);
    const url =
      "https://miraserver.quantino.in/mira/api/user-chat-sessions/" +
      item +
      "/";
    //console.log("URL : ", url);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    localStorage.setItem("session_id", item);
    //console.log("REsponse by session id , ", responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    // Handle error, e.g., show an error message to the user
  }
};

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

import PostAddIcon from "@mui/icons-material/PostAdd";

import TuneIcon from "@mui/icons-material/Tune";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import ArticleIcon from "@mui/icons-material/Article";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import * as UserService from "../../services/UserServices";
import * as Global from "../../services/Global";
import IconButton from "@mui/material/IconButton";

const BottomNavigationComponent = (props) => {
  const [sessionId, setSessionId] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const location = useLocation();
  // Assuming message is received as a prop
  const { messages } = props;

  // Function to handle change in history component
  const handleChange = (event) => {
    // Assuming some event occurs that changes the message in history component
    const newMessages = event.target.value;

    // Notify the parent component (chat component) about the change
    props.onMessageChange(newMessages);
  };
  React.useEffect(() => {}, []);

  const navigate = useNavigate();

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `Mira - User Guide.pdf`;
    link.href = "./Mira - User Guide.pdf";
    link.click();
  };

  const handleSupportButton = () => {
    window.open("https://bossify.ai/mira-support/", "_blank");
  };

  const { setPageTitles } = props;
  const handleNavClick = (value) => {
    navigate(value);
  };
  useEffect(() => {
    // Get the current path from the location object
    const { pathname } = location;

    // Define the paths for each list item
    const paths = ["/manage"];

    // Find the index of the current path in the paths array
    const index = paths.findIndex((path) => path === pathname);

    // Set the selected index based on the current path
    setSelectedIndex(index);
  }, [location]);

  return (
    <>
      <List
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Divider sx={{ background: "lightgrey" }} />
        <Tooltip title={"Configuration"}>
          <ListItem
            key="document"
            sx={{
              backgroundColor: selectedIndex === 0 ? "darkgreen" : "inherit",
            }}
            disablePadding
          >
            <ListItemButton onClick={() => handleNavClick("/manage")}>
              <ListItemIcon>
                <TuneIcon
                  sx={{
                    color: selectedIndex === 0 ? "white" : "darkslategrey",
                  }}
                />
              </ListItemIcon>

              <ListItemText>
                <Typography
                  sx={{
                    color: selectedIndex === 0 ? "white" : "#223f58",
                    fontWeight: 550,
                    color: selectedIndex === 0 ? "white" : "darkslategrey",
                  }}
                  noWrap
                >
                  Configuration
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Tooltip>
        <Tooltip title={"User Guide"}>
          <ListItem key="UserGuide" disablePadding>
            <ListItemButton
              onClick={() => {
                Global.navigateToNewTab("/help");
              }}
            >
              <ListItemIcon>
                <DocumentScannerIcon sx={{ color: "darkslategrey" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: 550, color: "#223f58" }} noWrap>
                  User Guide
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Tooltip>
        <Tooltip title="Support">
          <ListItem key="Support" disablePadding>
            <ListItemButton onClick={handleSupportButton}>
              <ListItemIcon>
                <ContactSupportIcon sx={{ color: "darkslategrey" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: 550, color: "#223f58" }} noWrap>
                  Support
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Tooltip>
        <Divider sx={{ background: "lightgrey" }} />
        <Tooltip title="Logout">
          <ListItem key="Logout" disablePadding>
            <ListItemButton onClick={() => UserService.logout()}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: "darkslategrey" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: 550, color: "#223f58" }} noWrap>
                  Logout
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </List>
    </>
  );
};
export default BottomNavigationComponent;

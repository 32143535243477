import React, { useState, useEffect, useRef } from "react";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { Grid, useMediaQuery } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";

import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link, Checkbox } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import TableRowsIcon from "@mui/icons-material/TableRows";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

const options = [
  "Analytical Development Process",
  "Process Development Analysis",
  "Other Process ",
  // Add more options as needed
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const ManageDocuments = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [responseAlert, setResponseAlert] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState("");
  const [currentFileDetails, setCurrentFileDetails] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const location = useLocation();
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [location]);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.", event.target.value);
  }
  const [fileDetails, setFileDetails] = useState([]);

  const chatEndRef = useRef(null); // Ref for the last message element
  React.useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
    fetchFilesDetail(storedAccessToken);
  }, []);
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [location]);

  useEffect(() => {
    fetchFilesDetail(accessToken);
  }, [accessToken, currentPage, selectedOption]);

  const fetchFilesDetail = async (storedAccessToken) => {
    try {
      const url = new URL(
        "https://miraserver.quantino.in/mira/api/get_uploaded_files/"
      );

      // Send user message to the backend
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + storedAccessToken,
        },
      });

      if (!response.ok) {
        // Handle error response
        throw new Error("Failed to fetch file details");
      }

      const responseData = await response.json();
      console.log("Response GET Data , ", responseData);

      // Update file details state
      setFileDetails(responseData);

      // Calculate pagination details
      const itemsPerPage = 5;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Update current file details state
      setCurrentFileDetails(responseData.slice(startIndex, endIndex));

      // Update total pages state
      setTotalPages(Math.ceil(responseData.length / itemsPerPage));
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleUpload = async () => {
    // Here you can trigger the API call to upload the file to the backend

    const storedAccessToken = localStorage.getItem("accessToken");
    try {
      // Construct URL with query parameters
      const url = new URL(
        "https://miraserver.quantino.in/mira/api/upload_documents/" +
          documentCategory +
          "/"
      );

      var formData = new FormData();
      formData.append("document", selectedFile);

      console.log("Uploading formData:", formData);
      // Send user message to the backend
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + storedAccessToken,
        },
        body: formData,
      });

      const responseData = await response.json();
      console.log("Response Data , ", responseData);

      if (!response.ok) {
        console.log("Not ok");

        setResponseAlert(false);
        setResponseAlert({ type: "error", message: responseData.message });
        setResponseMessage(responseData.message);
      } else {
        setResponseAlert({ type: "success", message: responseData.message });
      }
      setTimeout(() => {
        setResponseAlert(null);
      }, 6000);

      setResponseMessage(responseData.message);
      setDocumentCategory("");
      setSelectedFile("");
      setIsChecked();
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
  };
  const [value, setValue] = React.useState(0);
  const [documentCategory, setDocumentCategory] = React.useState("");

  const handleChange = (event, newValue) => {
    console.log("Valuw : ", newValue);
    setValue(newValue);
    setDocumentCategory(event.target.value);
  };

  // Function to handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xl={11}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{ mt: 0.5 }}
          >
            <Typography color="text.primary" sx={{ fontWeight: 600 }}>
              Manage Documents
            </Typography>
            <Divider />
            <Grid item xs={12} xl={12}>
              <Typography display="block" sx={{ fontSize: 13 }}>
                Please select the document type
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={documentCategory}
                  onChange={handleChange}
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="AD">
                    Analytical Development Analysis
                  </MenuItem>
                  <MenuItem value="PD">Process Development Analysis</MenuItem>
                  <MenuItem value="ELN">
                    Electronic Note Book (ELN) Analysis
                  </MenuItem>
                  <MenuItem value="ELN2">
                    Electronic Note Book (ELN - 2) Analysis
                  </MenuItem>
                  <MenuItem value="RESUME">Resume Analysis</MenuItem>
                  <MenuItem value="OTHER">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" sx={{ fontSize: 13 }} gutterBottom>
                Upload the file
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                size="small"
                startIcon={<CloudUploadIcon />}
                sx={{
                  background: "#448a9d",
                  "&:hover": {
                    background: "rgb(27 98 118)",
                    color: "white",
                  },
                }}
              >
                Upload file
                <VisuallyHiddenInput onChange={handleFileChange} type="file" />
              </Button>
              {selectedFile && <Typography>{selectedFile.name}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              <Typography
                display="inline"
                sx={{ fontSize: 11, fontWeight: 600 }}
                gutterBottom
              >
                Please make sure the document type before uploaded into the
                Mira.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleUpload(selectedOption?.id)} // Pass the selected option ID to handleUpload
                disabled={!isChecked || !selectedFile}
                sx={{
                  background: "#448a9d",
                  "&:hover": {
                    background: "rgb(27 98 118)",
                    color: "white",
                  },
                }}
              >
                Submit <SendIcon sx={{ marginLeft: 2, color: "white" }} />
              </Button>
              {responseAlert !== null && (
                <div>
                  {responseAlert.type === "success" ? (
                    <Alert severity="success" sx={{ mt: 2, mb: 1 }}>
                      {responseAlert.message}
                    </Alert>
                  ) : (
                    <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
                      {responseAlert.message}
                    </Alert>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              sx={{ fontSize: 15, mt: 2 }}
              gutterBottom
              fontWeight="600"
            >
              Ingested File List
            </Typography>
            <Divider />
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item xl={12}>
                {Array.isArray(fileDetails) && fileDetails.length > 0 ? (
                  <>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead
                          sx={{
                            background: "rgb(27 98 118)",
                            color: "white",
                            padding: 0,
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                color: "white",
                                padding: "12px",
                              }}
                            >
                              File Name
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                color: "white",
                                padding: "12px",
                              }}
                            >
                              File Category
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                color: "white",
                                padding: "12px",
                              }}
                            >
                              Uploaded By
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentFileDetails.map((fileDetail) => (
                            <TableRow
                              key={fileDetail.file_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontSize: 13 }}
                              >
                                {fileDetail.file_name}
                              </TableCell>
                              <TableCell sx={{ fontSize: 13 }}>
                                {fileDetail.file_category}
                              </TableCell>
                              <TableCell sx={{ fontSize: 13 }}>
                                {fileDetail.uploaded_by}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      size="small"
                      sx={{
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    />
                  </>
                ) : (
                  <Typography variant="body2">
                    No files uploaded yet.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ManageDocuments;

import * as historyService from "../services/HistoryService";

export const fetchUserFavoritesByAccessToken = async (accessToken) => {
  try {
    // Send user message to the backend

    const response = await fetch(
      "https://miraserver.quantino.in/mira/api/get-user-favorites/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      //navigate("/");
      console.error("Error code:", response.status);
      throw new Error("Failed to submit data");
    }

    const responseData = await response.json();
    return responseData;

    // Update messages state with AI response
    //const aiMessage = { text: responseData.answer, sender: 'AI', graphInput: responseData.graph_input, options: responseData.options };
  } catch (error) {
    console.error("Error:", error.message);
    // Handle error, e.g., show an error message to the user
  }
};

export const markFavoriteApi = async (chat_id) => {
  try {
    const url =
      "https://miraserver.quantino.in/mira/api/mark-as-favorite/" +
      chat_id +
      "/";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    // Handle error, e.g., show an error message to the user
  }
};

export const markUnFavoriteApi = async (chat_id) => {
  try {
    const url =
      "https://miraserver.quantino.in/mira/api/un-mark-as-favorite/" +
      chat_id +
      "/";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    // Handle error, e.g., show an error message to the user
  }
};

export const handleFavoriteDelete = async (chat_id) => {
  //console.log("Delete chat_id : ", chat_id);
  try {
    const url =
      "https://miraserver.quantino.in/mira/api/un-mark-as-favorite/" +
      chat_id +
      "/";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    //setDeleteFavorite(!deleteFavorite);
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    // Handle error, e.g., show an error message to the user
  }
};

export const messageResponse = async () => {
  let storedAccessToken = localStorage.getItem("accessToken");
  let current_session = localStorage.getItem("session_id");
  let historyMessages = [];

  if (current_session) {
    // Fetch history by session ID
    historyService
      .fetchHistoryBySessionId(current_session)
      .then((messages) => {
        // Process the messages and set state
        let tempMessages = [];
        // Map over selectedHistory and create new message objects
        messages.forEach((historyItem) => {
          const promptResponseDict = JSON.parse(
            historyItem.prompt_response_dict
          );
          let userMessage = {
            sender: "user",
            graphInput: promptResponseDict.graph_input,
            options: promptResponseDict.options,
            text: promptResponseDict.prompt,
            chat_id: historyItem["chat_id"],
            favoritedResponse: historyItem["is_favorite"],
          };
          let miraMessage = {
            sender: "mira",
            graphInput: promptResponseDict.graph_input,
            options: promptResponseDict.options,
            text: promptResponseDict.answer,
          };
          tempMessages.push(userMessage);
          tempMessages.push(miraMessage);
        });
        return tempMessages;
        //console.log("setMessages message : ", tempMessages);
      })
      .catch((error) => {
        console.error("Error fetching history:", error);
      });
  }
};

import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function UserGuide() {
  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #555",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Box>
      <object
        data="/Mira-UserGuide.pdf"
        type="application/pdf"
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
        }}
      >
        <p>
          User Guide <a href="Mira-UserGuide.pdf"></a>
        </p>
      </object>
    </Box>
  );
}

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const getLocalStore = (key) => {
  return localStorage.getItem(key);
};

export const setLocalStore = (key, value) => {
  let counter = 0;
  while (getLocalStore(key) !== value && counter < 100) {
    localStorage.setItem(key, value);
    counter++;
  }
};

export const removeLocalStore = (key) => {
  let counter = 0;
  while (getLocalStore(key) !== null && counter < 100) {
    localStorage.removeItem(key);
    counter++;
  }
};

export const navigateTo = (path) => {
  window.location = path;
};

export const navigateToNewTab = (path) => {
  window.open(path, "_blank");
};

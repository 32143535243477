import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";

import { withStyles } from "@mui/styles";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ChatOptions from "../chat/ChatOptions";
import Zoom from "@mui/material/Zoom";
import BottomNavigationComponent from "../common/BottomNavigationComponent";
import * as historyService from "../../services/HistoryService";
import * as UserService from "../../services/UserServices";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import UserProfile from "../common/UserProfile";
import MiraLogo from "../../assets/logo-2.png";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "whitesmoke",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: "whitesmoke",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const drawerWidthLeft = 0;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidthLeft,
    width: `calc(100% - ${drawerWidthLeft}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "black",
    border: "2px solid darkgreen",
    backgroundColor: "white",
    borderRadius: "10px",
    fontWeight: 500,
    maxWidth: 300,
  },
})(Tooltip);

export default function MainLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openLeft, setOpenLeft] = React.useState(true);
  const [openRight, setOpenRight] = React.useState(true);
  const [newChat, setNewChat] = useState(false);
  const [historyMessages, setHistoryMessages] = useState([]);
  const [accessToken, setAccessToken] = React.useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [selectedIndex, setSelectedIndex] = useState();
  const [updateFavorites, setUpdateFavorites] = useState();
  const [updateDeleteFavorites, setUpdateDeleteFavorites] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [historyMessageBySessionId, setHistoryMessageBySessionId] = useState(
    []
  );
  const [count, setCount] = React.useState(10);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
    // if (pathname === "/") {
    //   setPageTitle("A Digital Chief of Staff");
    // } else if (pathname === "/manage") {
    //   setPageTitle("Configuration");
    // }
    setPageTitle("AI Chief of Staff");
    let historyMessages = "";
    historyMessages =
      historyService.fetchHistoryMessageByAccessToken(storedAccessToken);

    historyMessages.then((messages) => {
      setHistoryMessages(messages);
    });
  }, []);

  const handleDrawerLeftOpen = () => {
    setOpenLeft(!openLeft);
  };
  const handleDrawerRightOpen = () => {
    setOpenRight(!openRight);
  };

  const handleNavClick = (value) => {
    if (value === "/") {
      setPageTitle("AI Chief of Staff");
      const storedAccessToken = localStorage.getItem("accessToken");
      if (storedAccessToken) {
        setAccessToken(storedAccessToken);
      }
      let historyServiceResponse = "";
      historyServiceResponse =
        historyService.fetchHistoryMessageByAccessToken(accessToken);

      historyServiceResponse.then((messages) => {
        setHistoryMessages(messages);
      });

      localStorage.setItem("session_id", UserService.generateSessionId());
      setNewChat(!newChat);
      setHistoryMessageBySessionId([]);
      navigate(value);
    } else {
      setPageTitle("AI Chief of Staff");
      navigate(value);
    }
  };

  const handleListItemClick = async (index, item) => {
    setSelectedIndex(index);
    let fetchHistoryBySessionIdResponse = "";
    try {
      fetchHistoryBySessionIdResponse = historyService.fetchHistoryBySessionId(
        item.session_id
      );

      fetchHistoryBySessionIdResponse.then((messages) => {
        //setMessages(messages);
        setHistoryMessageBySessionId(messages);
        //console.log("History select : ", messages);
      });
    } catch (error) {}
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          //background: '#075b74',
          background: "whitesmoke",
          color: "#024f4f",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Toolbar>
            <img
              src={MiraLogo}
              alt="logo"
              style={{ width: 50, height: "auto", marginRight: 10 }}
            />
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#223f58",
                fontSize: "27px",
              }}
              noWrap
            >
              {pageTitle}
            </Typography>
          </Toolbar>
          <UserProfile />
        </Grid>
      </AppBar>
      <Drawer variant="permanent" open={openLeft}>
        <Toolbar />
        <Divider />
        <List>
          {" "}
          <Tooltip title={"New Chat"}>
            <ListItem key="New Chat" disablePadding>
              <ListItemButton onClick={() => handleNavClick("/")}>
                <ListItemIcon sx={{ color: "darkslategrey" }}>
                  <PostAddIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      color: "#223f58",
                      fontWeight: 600,
                    }}
                  >
                    New Chat
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
        <Divider sx={{ width: "100%", background: "lightgrey" }} />
        <List>
          <Tooltip title={"History"}>
            <ListItem key="history-title">
              <ListItemIcon sx={{ color: "darkslategrey" }}>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: 550, color: "#223f58" }} noWrap>
                  History
                </Typography>
              </ListItemText>
            </ListItem>
          </Tooltip>
        </List>
        <Divider sx={{ width: "100%", background: "lightgrey" }} />
        {openLeft ? (
          <List
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              maxHeight: 260,
              color: "#223f58",
            }}
          >
            {Array.isArray(historyMessages) &&
              historyMessages.slice(0, 100).map((item, index) => (
                <>
                  <BlueOnGreenTooltip
                    placement="left"
                    TransitionComponent={Zoom}
                    title={item.message}
                    arrow
                  >
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: 0,
                        paddingRight: 0,
                        background:
                          index === selectedIndex ? "darkgreen " : "inhert",
                        color: index === selectedIndex ? "white" : "inhert",
                      }}
                    >
                      <ListItemButton
                        onClick={(value) => handleListItemClick(index, item)}
                      >
                        <ListItemText>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontWeight: 520,
                              fontSize: "0.75rem",
                              color: "blacksmoke",
                            }}
                          >
                            {item.message}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </BlueOnGreenTooltip>
                </>
              ))}
          </List>
        ) : null}

        <BottomNavigationComponent />
      </Drawer>
      <Box
        sx={{
          mt: 6,
          ml: openLeft ? 28 : 6,
          position: "fixed",
          top: theme.spacing(2),
          left: theme.spacing(2),
          zIndex: theme.zIndex.drawer + 1,
          borderBottomRightRadius: "20px",
        }}
      >
        {openLeft ? (
          <>
            <List
              onClick={handleDrawerLeftOpen}
              sx={{
                background: "#966994",
                borderBottomRightRadius: "20px",
              }}
            >
              <ChevronLeftIcon
                sx={{
                  color: "whitesmoke",
                }}
              />
            </List>
          </>
        ) : (
          <>
            <List
              onClick={handleDrawerLeftOpen}
              sx={{
                background: "#966994",
                borderBottomRightRadius: "15px",
              }}
            >
              <ChevronRightIcon
                sx={{
                  color: "white",
                }}
              />
            </List>
          </>
        )}
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet
          context={[
            historyMessageBySessionId,
            newChat,
            updateFavorites,
            setUpdateFavorites,
            selectedPrompt,
            updateDeleteFavorites,
            setUpdateDeleteFavorites,
            openRight,
            openLeft,
            setSelectedPrompt,
          ]}
        />
      </Box>
      <Box
        sx={{
          background: openRight ? "#016969" : "whitesmoke",
          mt: 6,
          mr: openRight ? 28 : 6,
          position: "fixed",
          top: theme.spacing(2),
          right: theme.spacing(2),
          zIndex: theme.zIndex.drawer + 1,
          borderBottomLeftRadius: "20px",
        }}
      >
        {openRight ? (
          <>
            <List
              onClick={handleDrawerRightOpen}
              sx={{
                background: "#966994",
                borderBottomLeftRadius: "20px",
              }}
            >
              <ChevronRightIcon
                sx={{
                  color: "whitesmoke",
                }}
              />
            </List>
          </>
        ) : (
          <>
            <List
              onClick={handleDrawerRightOpen}
              sx={{
                background: "#966994",
                borderBottomLeftRadius: "15px",
              }}
            >
              <ChevronLeftIcon
                sx={{
                  color: "white",
                }}
              />
            </List>
          </>
        )}
      </Box>
      <Drawer
        open={openRight}
        variant="permanent"
        anchor="right"
        PaperProps={{
          style: {
            background: "whitesmoke",
            mixWidth: openRight ? "250px" : "75px",
          },
        }}
      >
        <Toolbar />
        <Divider />

        {openRight ? (
          <ChatOptions
            updateDeleteFavorites={updateDeleteFavorites}
            setUpdateFavorites={setUpdateFavorites}
            setSelectedPrompt={setSelectedPrompt}
            setUpdateDeleteFavorites={setUpdateDeleteFavorites}
          />
        ) : (
          <>
            <List sx={{ color: "darkslategrey", ml: 2, mt: 1 }}>
              <BackupTableIcon />
            </List>
            <Divider sx={{ width: "100%", background: "lightgrey" }} />
          </>
        )}
      </Drawer>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoginIcon from "@mui/icons-material/Login"; // Import a login icon
import Alert from "@mui/material/Alert";
import * as Global from "../../services/Global";
import * as UserService from "../../services/UserServices";

const Login = ({ children }) => {
  // State variables to hold email and password values
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loginError, setLoginError] = useState(false); // State variable to track login error

  // Function to handle changes in the email input field
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  // Function to handle changes in the password input field
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginError(false);
    if ((await UserService.login(username, password)) === true) {
      setIsLoggedIn(true);
      Global.navigateTo("/");
    } else {
      setLoginError(true);
    }
  };

  useEffect(() => {
    UserService.isUserLoggedIn().then((result) => {
      if (result === true) {
        Global.navigateTo("/");
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      {children}
    </Box>
  );
};

export default Login;

import * as Global from "./Global";

export const refreshAccessToken = async () => {
  try {
    const authorized = await fetch(
      "https://miraserver.quantino.in/mira/api/token/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: Global.getLocalStore("refreshToken"),
        }),
      }
    )
      .then(async (result) => {
        if (result.ok) {
          const responseData = await result.json();
          Global.setLocalStore("accessToken", responseData.access);
          Global.setLocalStore("refreshToken", responseData.refresh);
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
    return authorized;
  } catch (error) {
    return false;
  }
};

export const isUserLoggedIn = async () => {
  if (Global.getLocalStore("refreshToken") === null) {
    return false;
  } else {
    return await refreshAccessToken().then((result) => {
      return result;
    });
  }
};

export const loginSync = async (username, password) => {
  // let authenticated = false;
  try {
    const authenticated = await fetch(
      "https://miraserver.quantino.in/mira/api/token/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      }
    )
      .then(async (result) => {
        if (result.ok) {
          const responseData = await result.json();
          Global.setLocalStore("username", username);
          Global.setLocalStore("accessToken", responseData.access);
          Global.setLocalStore("refreshToken", responseData.refresh);
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
    return authenticated;
  } catch (error) {
    return false;
  }
};

export const login = async (username, password) => {
  return await loginSync(username, password).then((result) => {
    return result;
  });
};

const clearLoginData = () => {
  Global.removeLocalStore("accessToken");
  Global.removeLocalStore("refreshToken");
  Global.removeLocalStore("username");
  Global.removeLocalStore("session_id");
};

export const logout = () => {
  clearLoginData();
  Global.navigateTo("/login");
};

export const generateSessionId = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const length = 10; // Length of the generated ID
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoginIcon from "@mui/icons-material/Login"; // Import a login icon
import Alert from "@mui/material/Alert";
import * as Global from "../../services/Global";
import * as UserService from "../../services/UserServices";
import Logo from "../../assets/logo-2.png";

const Login = () => {
  // State variables to hold email and password values
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loginError, setLoginError] = useState(false); // State variable to track login error

  // Function to handle changes in the email input field
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  // Function to handle changes in the password input field
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginError(false);
    if ((await UserService.login(username, password)) === true) {
      setIsLoggedIn(true);
      localStorage.setItem("session_id", UserService.generateSessionId());

      Global.navigateTo("/");
    } else {
      setLoginError(true);
    }
  };

  useEffect(() => {
    UserService.isUserLoggedIn().then((result) => {
      if (result === true) {
        Global.navigateTo("/");
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  return isLoggedIn ? (
    <></>
  ) : (
    <ThemeProvider theme={Global.theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "whitesmoke",
          // backgroundImage: 'url("page-bg.jpg")',
          // backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light"
          //     ? t.palette.grey[50]
          //     : t.palette.grey[900],
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          p: { xs: 1, sm: 2 },
        }}
      >
        <Card
          raised
          sx={{
            maxWidth: 950,
            width: "100%",
            display: "flex",
            overflow: "hidden",
            background: "whitesmoke",
            borderRadius: { xs: "0", md: "16px" },
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              maxWidth: { md: "60%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: { xs: 2, md: 6 },
              background: "white",
            }}
          >
            <Box
              sx={{
                pt: { xs: 2, md: 0 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{ width: 120, height: "auto" }}
              />
            </Box>
            <Box
              sx={{
                mb: 4,
                pt: { xs: 1, md: 0 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ display: { xs: "block", md: "none" } }}
              >
                A AI Chief of Staff
              </Typography>
            </Box>
            <Box sx={{ ml: 1 }}>
              <Typography variant="h5">Sign In:</Typography>
            </Box>
            {/* Attach change handlers to the TextField components */}
            <TextField
              label="Email"
              variant="outlined"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              fullWidth
              margin="normal"
              autoComplete="false"
            />
            <TextField
              label="Password"
              variant="outlined"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              type="password"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Remember Me"
              sx={{ my: 2 }}
              name="chkRemember"
            />
            {/* Attach form submission handler to the Button component */}
            <Button
              variant="contained"
              startIcon={<LoginIcon />}
              fullWidth
              sx={{
                mb: 4,
                background: "darkgreen",
                "&:hover": {
                  background: "darkgreen",
                  color: "white",
                },
              }}
              onClick={handleSubmit}
              name="btnSubmit"
            >
              Login
            </Button>
            {/* Show alert bar based on login status */}
            {isLoggedIn ? (
              <Alert severity="success" sx={{ mb: 2 }}>
                Login successful
              </Alert>
            ) : loginError ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                Login failed
              </Alert>
            ) : null}
          </CardContent>
          <Box
            sx={{
              display: { xs: "none", md: "block" }, // Hide on small screens
              width: "40%",
              background: "#034903",
              p: Global.theme.spacing(4),
            }}
            color={"whitesmoke"}
          >
            <Typography variant="h2" sx={{ mb: 2 }}>
              Mira
            </Typography>
            <Typography variant="h5" sx={{ mb: 6 }}>
              An AI Chief of Staff
            </Typography>
            <Typography variant="body1">
              I’m an AI-driven Chief of Staff able to support a Pharma or
              Biotech executive.
            </Typography>
            <br />
            <Typography variant="body1">
              I deliver “Organizational Intelligence” to enhance the
              effectiveness of their functional leadership teams.
            </Typography>
            <br />
            <Typography variant="body1">
              I’m able to distill divisional scientific and operational
              activities into strategic initiatives, safeguarding against small
              problems snowballing into company-wide calamities or “fire
              drills.”
            </Typography>
            <br />
          </Box>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default Login;

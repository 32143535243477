export const fetchEmailContent = async (chat_id) => {
  // Make API call based on the index

  const accessToken = localStorage.getItem("accessToken");

  let url =
    "https://miraserver.quantino.in/mira/api/get-email-content/" +
    chat_id +
    "/";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to submit data", response);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    // Handle error, e.g., show an error message to the user
  }
};

import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate, useOutletContext } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, useMediaQuery } from "@mui/material";
import AssistantIcon from "@mui/icons-material/Assistant";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as UserService from "../../services/UserServices";
import MiraLogo from "../../assets/logo-2.png";
import TextField from "@mui/material/TextField";
import DynamicGraph from "../Graph/DynamicGraph";
import IosShareIcon from "@mui/icons-material/IosShare";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
//import ShareHistoryExport from "../History/ShareHistory";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Alert from "@mui/material/Alert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StarIcon from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import TaskIcon from "@mui/icons-material/Task";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import emailjs from "emailjs-com";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import * as historyService from "../../services/HistoryService";
import * as favoriteService from "../../services/FavoriteServices";
import * as promptService from "../../services/PromptService";

import EmailIcon from "@mui/icons-material/Email";

const drawerWidth = 55;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "3px solid darkslategrey",
  boxShadow: 24,
  borderRadius: 4,
  padding: 2,
  width: "50%",
};

const styleEmail = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "3px solid darkslategrey",
  boxShadow: 24,
  borderRadius: 3,
  width: "50%",
};

function ChatComponent() {
  const theme = useTheme();
  const [
    historyMessageBySessionId,
    newChat,
    updateFavorites,
    setUpdateFavorites,
    selectedPrompt,
    updateDeleteFavorites,
    setUpdateDeleteFavorites,
    openRight,
    openLeft,
    setSelectedPrompt,
  ] = useOutletContext();
  //const [newChat] = useOutletContext();

  const [open, setOpen] = React.useState(false);
  const [htmlContent, setHtmlContent] = useState();
  const [inputValue, setInputValue] = useState("");
  const [graphInput, setGraphInput] = useState(false);
  const [options, setOptions] = useState([]);
  const [messages, setMessages] = useState([]);
  const [updatedInputValue, setUpdatedInputValue] = useState("");
  const [username, setUsername] = React.useState("");
  const [isWating, setIsWating] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState("");
  const [copyUrl, setCopyUrl] = React.useState("");
  const chatEndRef = useRef(null); // Ref for the last message element
  const [chatId, setChatId] = React.useState(false);
  const [sessionId, setSessionId] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [copyLink, setCopyLink] = useState("");
  const [reLoadPage, setReLoadPage] = useState(false);
  const [emailContent, setEmailContent] = useState({});
  const [emailContentForEmailJS, setEmailContentForEmailJS] = useState();
  const [fullEmailContent, setFullEmailContent] = useState({});
  const [openEmailContent, setOpenEmailContent] = React.useState(false);
  const [CopyEmailContent, setCopyEmailContent] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(false);
  const [scrollToB, setScrollToB] = useState(false);
  const [firstPrompt, setFirstPrompt] = useState();

  const handleShare = () => {
    console.log("Session ID : ", sessionId);
    let url = "/share/" + localStorage.getItem("session_id") + "/";
    setCopyUrl(url);
    setIsCopied(false);
    setCopyLink("https://mira.quantino.in" + url);
    setOpen(true);
  };
  const handleSendClick = async () => {
    if (inputValue.trim() === "") {
      // Display error or return if the message is empty
      return;
    }
    setScrollToB(true);
    setFirstPrompt(false);
    setIsWating(true);
    let tempMessages = [];

    try {
      // Send user message to the backend
      const response = await fetch(
        "https://miraserver.quantino.in/mira/api/prompt_route/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            user_prompt: inputValue,
            session_id: localStorage.getItem("session_id"),
          }),
        }
      );

      if (!response.ok) {
        console.log("Code : ", response.status);
        if (response.status === 401) {
          UserService.logout();
        }
        //navigate("/");
        throw new Error("Failed to submit data");
      }

      const responseData = await response.json();
      // Update messages state with AI response

      const aiMessage = {
        text: responseData.prompt_response_dict.answer,
        sender: "AI",
        graphInput: responseData.prompt_response_dict.graph_input,
        options: responseData.prompt_response_dict.options,
      };
      let userMessage = {
        sender: "user",
        text: inputValue,
        chat_id: responseData.chat_id,
        favoritedResponse: responseData.is_favorite,
      };

      tempMessages.push(userMessage);
      tempMessages.push(aiMessage);

      setHtmlContent(aiMessage.text);
      setGraphInput(aiMessage.graphInput);
      if (graphInput == true) {
        setOptions(aiMessage.options);
      }

      setMessages((prevMessages) => [...prevMessages, ...tempMessages]);
      setInputValue("");
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
    setIsWating(false);
    // Clear input value after sending
    setInputValue("");
    setScrollToB(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleToEmailChange = (event) => {
    setToEmail(event.target.value);
  };

  useEffect(() => {
    if (selectedPrompt !== "") {
      setInputValue(selectedPrompt);
      setSelectedPrompt("");
    }
  }, [selectedPrompt]);

  useEffect(() => {
    //console.log("New chat....");
    setMessages([]);
    setFirstPrompt(true);
    setUsername(localStorage.getItem("username"));
    setInputValue("");
  }, [newChat]);

  useEffect(() => {
    const updatedMessages = messages.map((message) => {
      console.log("Message from chat component : ", updateFavorites);
      if (message.chat_id === updateFavorites) {
        return {
          ...message,
          favoritedResponse: !message.favoritedResponse,
        };
      }
      return message;
    });
    setScrollToB(false);
    setMessages(updatedMessages);
    setUpdateFavorites("");
  }, [updateFavorites]);

  useEffect(() => {
    //console.log("Reload....");
    let storedAccessToken = localStorage.getItem("accessToken");
    let current_session = localStorage.getItem("session_id");
    let historyMessages = [];

    if (current_session) {
      setAccessToken(storedAccessToken);

      // Fetch history by session ID
      historyService
        .fetchHistoryBySessionId(current_session)
        .then((messages) => {
          // Process the messages and set state
          let tempMessages = [];
          // Map over selectedHistory and create new message objects
          messages.forEach((historyItem) => {
            const promptResponseDict = JSON.parse(
              historyItem.prompt_response_dict
            );
            let userMessage = {
              sender: "user",
              graphInput: promptResponseDict.graph_input,
              options: promptResponseDict.options,
              text: promptResponseDict.prompt,
              chat_id: historyItem["chat_id"],
              favoritedResponse: historyItem["is_favorite"],
            };
            let miraMessage = {
              sender: "mira",
              graphInput: promptResponseDict.graph_input,
              options: promptResponseDict.options,
              text: promptResponseDict.answer,
            };
            tempMessages.push(userMessage);
            tempMessages.push(miraMessage);
          });
          setMessages(tempMessages);
          //console.log("setMessages message : ", tempMessages);
        })
        .catch((error) => {
          console.error("Error fetching history:", error);
        });
    }
  }, []);

  useEffect(() => {
    // Process the messages and set state
    let tempMessages = [];
    // Map over selectedHistory and create new message objects
    if (historyMessageBySessionId !== undefined) {
      historyMessageBySessionId.forEach((historyItem) => {
        const promptResponseDict = JSON.parse(historyItem.prompt_response_dict);
        let userMessage = {
          sender: "user",
          graphInput: promptResponseDict.graph_input,
          options: promptResponseDict.options,
          text: promptResponseDict.prompt,
          chat_id: historyItem["chat_id"],
          favoritedResponse: historyItem["is_favorite"],
        };
        let miraMessage = {
          sender: "mira",
          graphInput: promptResponseDict.graph_input,
          options: promptResponseDict.options,
          text: promptResponseDict.answer,
        };
        tempMessages.push(userMessage);
        tempMessages.push(miraMessage);
      });
      setMessages(tempMessages);
    }
  }, [historyMessageBySessionId]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(copyLink)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000); // Reset copied state after 2 seconds
      })
      .catch((error) => console.error("Failed to copy:", error));
  };
  useEffect(() => {
    if (scrollToB) {
      scrollToBottom();
    }

    setScrollToB(false);
    //handleSendClick();
  }, [messages, scrollToB]); // Scroll to bottom whenever messages change

  const scrollToBottom = () => {
    chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const navigate = useNavigate();

  const handleCopyClick = () => {
    window.open(copyUrl, "_blank");
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEmailContent(false);
  };
  const handleFavoriteApi = async (chat_id) => {
    try {
      favoriteService.markFavoriteApi(chat_id).then((responseMessage) => {
        if (responseMessage !== undefined) {
          const updatedMessages = messages.map((message) => {
            if (message.chat_id === chat_id) {
              return {
                ...message,
                favoritedResponse: !message.favoritedResponse,
              };
            }
            return message;
          });
          setScrollToB(false);
          setMessages(updatedMessages);
          setUpdateDeleteFavorites(!updateDeleteFavorites);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleUnFavoriteApi = async (chat_id) => {
    try {
      favoriteService.markUnFavoriteApi(chat_id).then((responseMessage) => {
        if (responseMessage !== undefined) {
          const updatedMessages = messages.map((message) => {
            if (message.chat_id === chat_id) {
              return {
                ...message,
                favoritedResponse: !message.favoritedResponse,
              };
            }
            return message;
          });
          setScrollToB(false);
          setMessages(updatedMessages);
          //console.log("Delect Favorite", chat_id);
          setUpdateDeleteFavorites(!updateDeleteFavorites);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  // Function to handle favorite click
  const handleFavoriteClick = (chat_id, is_favorite) => {
    // Toggle the favorite status
    const newFavoriteStatus = !is_favorite;
    // Update the favorite status in the state or make API calls
    let chat__id = chat_id["chat_id"];
    if (newFavoriteStatus) {
      handleFavoriteApi(chat__id);
    } else {
      handleUnFavoriteApi(chat__id);
    }
    // Update the message's favorite status
    //updateMessageFavoriteStatus(chat__id, newFavoriteStatus);
  };

  const handleDrawerOpen = () => {
    console.log("Handle clicked :");
    setOpen(!open);
  };
  // Function to update message in chat component
  const handleMessageChange = (newMessage) => {
    setMessages(newMessage);
    setUpdatedInputValue("");
  };
  const handleSingleShareOpen = (chat_id) => {
    let url = "/share/" + localStorage.getItem("session_id") + "/" + chat_id;
    console.log("Url : ", url);
    setCopyUrl(url);
    setCopyLink("https://mira.quantino.in" + url);
    setChatId(chat_id);
    setOpen(true);
  };
  const handleEmailContent = (chat_id) => {
    setToEmail("");
    setIsWating(true);
    setIsGenerateEmail(true);
    promptService.fetchEmailContent(chat_id).then((messages) => {
      console.log("Response Prompt service : ", messages);
      if (messages !== undefined) {
        const titleRegex = /<title>(.*?)<\/title>/;
        const matches = messages["email_content"].match(titleRegex);
        const title = matches ? matches[1] : ""; // If title not found, use a default title

        // Replacing the hardcoded title with the dynamically extracted title
        const modifiedResponse = messages["email_content"].replace(
          "<body>",
          `<body>\n<h3>${title}</h3>`
        );
        const rawContent = messages["email_content"].replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );
        setCopyEmailContent(rawContent);
        setEmailContent(modifiedResponse);
        setFullEmailContent(messages);

        setOpenEmailContent(true);
        setIsWating(false);
        setIsGenerateEmail(false);
      }
    });
  };
  const handleCopyEmailContent = () => {
    navigator.clipboard
      .writeText(CopyEmailContent)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 10000); // Reset copied state after 2 seconds
      })
      .catch((error) => console.error("Failed to copy:", error));
  };

  const handleSendEmail = (emailContent) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(toEmail)) {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 5000);
    } else {
      setEmailError(false);
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(emailContent, "text/html");
    const title = doc.querySelector("title").textContent;
    const content = doc.querySelector("body").textContent.replace(title, "");

    let sendEmail = {
      subject: title,
      content: content,
      toEmail: toEmail,
    };
    emailjs
      .send(
        "service_cnerm6c",
        "template_r0q7bhs",
        sendEmail,
        "BmXNJwRtrjB0aUgRu"
      )
      .then(
        (response) => {
          console.log("response");
          setEmailStatus(true);
          console.log("SUCCESS!", response.status, response.text);
          setTimeout(() => {
            setEmailStatus(false);
          }, 5000);
          setToEmail("");
        },
        (err) => {
          console.log("FAILED...", err);
          //setShowSuccessAlert(false);
        }
      );
  };
  const [isGenerateEmail, setIsGenerateEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          pb: 12,
        }}
      >
        <Grid item sm={12} md={12} lg={12} xl={12} sx={{ minHeight: "auto" }}>
          {messages.length === 0 && firstPrompt ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "70vh" }}
            >
              <img
                src={MiraLogo}
                alt="logo"
                style={{ width: 170, height: "auto" }}
              />
              <Typography
                variant="body2"
                sx={{
                  p: 2,
                  fontWeight: 500,
                  color: "black",
                  textAlign: "center",
                }}
              >
                Type your query in the "User Prompt:" and click "Send"
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          {messages.map((message, index) => (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              key={index}
              sx={{
                padding: 1,
              }}
              spacing={1}
            >
              <Grid item>
                <ListItemIcon sx={{ minWidth: 0 }}>
                  {message.sender === "user" ? (
                    <PersonIcon sx={{ color: "darkslategrey" }} />
                  ) : (
                    <img
                      src={MiraLogo}
                      alt="logo"
                      style={{ width: 20, height: "auto" }}
                    />
                  )}
                </ListItemIcon>
              </Grid>
              <Grid item>
                {" "}
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color:
                      message.sender === "user" ? "darkslategrey" : "darkgreen",
                  }}
                  noWrap
                >
                  {message.sender === "user" ? username : "Mira:"}
                </Typography>
              </Grid>
              <Grid item>
                {message.sender === "user" && !isWating ? (
                  <>
                    <Tooltip title="Share" arrow>
                      <IosShareIcon
                        sx={{
                          ml: 5,
                          color: "#966994",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        fontSize="small"
                        onClick={() =>
                          handleSingleShareOpen(message["chat_id"])
                        }
                      />
                    </Tooltip>

                    <Tooltip title="Email Draft" arrow>
                      <EmailIcon
                        sx={{
                          ml: 2,
                          color: "#966994",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        fontSize="small"
                        onClick={() => handleEmailContent(message["chat_id"])}
                      />
                    </Tooltip>

                    {/* Conditional rendering of favorite icon */}
                    {message["favoritedResponse"] ? (
                      <Tooltip title="UnFavorite" arrow>
                        <StarIcon
                          sx={{
                            ml: 2,
                            color: "#966994",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          fontSize="small"
                          onClick={() =>
                            handleFavoriteClick(
                              message,
                              message["favoritedResponse"]
                            )
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add Favorite" arrow>
                        <StarOutlineIcon
                          onClick={() =>
                            handleFavoriteClick(
                              message,
                              message["favoritedResponse"]
                            )
                          }
                          sx={{ ml: 2, color: "#966994", fontSize: 17 }}
                          fontSize="small"
                        />
                      </Tooltip>
                    )}
                  </>
                ) : null}
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={12} sx={{ pt: "4px" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={7} md={7} lg={7} xl={9}>
                    {message.sender === "user"
                      ? null
                      : message.graphInput && (
                          <DynamicGraph options={message.options} />
                        )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={12}>
                {" "}
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    marginLeft: "20px",
                    color: "black ",
                    background:
                      message.sender === "user" ? "#e8eaed" : "#e8eaed",
                    border: "2px solid #acacac",
                    p: 2,

                    borderRadius: "15px",
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: message.text,
                  }}
                />
              </Grid>
            </Grid>
          ))}
          {isWating && !isGenerateEmail ? (
            <Box key="skeleton" sx={{ textAlign: "left" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  padding: 1,
                }}
                spacing={1}
              >
                <Grid item>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <PersonIcon />
                  </ListItemIcon>
                </Grid>
                <Grid item>
                  {" "}
                  <Typography
                    sx={{ fontWeight: "bold", color: "darkslategrey" }}
                    noWrap
                  >
                    {username}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      marginLeft: "40px",
                      color: "black ",
                      background: "whitesmoke",
                      border: "1px solid lightgrey",
                      p: 2,
                      borderRadius: 4,
                    }}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: inputValue,
                    }}
                  />
                </Grid>
              </Grid>

              <Stack spacing={1} direction="row" alignItems="flex-start">
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <img
                    src={MiraLogo}
                    alt="logo"
                    style={{ width: 20, height: "auto" }}
                  />
                </ListItemIcon>
                <Typography sx={{ fontWeight: "bold" }} noWrap>
                  Mira:
                </Typography>
              </Stack>
              <Box
                sx={{
                  width: "100%",
                  pt: 4,
                  pb: 4,
                  pl: { xs: 4, sm: 4, md: 8, lg: 12, xl: 16 },
                  pr: { xs: 4, sm: 4, md: 8, lg: 12, xl: 16 },
                }}
              >
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Grid>

        <Grid
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
          sx={{
            display: "flex",
            minWidth: "100%",
            position: "fixed",
            bottom: 0,
            pr: openRight ? (openLeft ? drawerWidth : 25) : openLeft ? 25 : 0,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              bottom: 0,
              background: "white",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              alignItems: "center",
              padding: 2,
              transition: theme.transitions.create(["width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
            spacing={2}
          >
            <Grid item sm={7} md={7} lg={8} xl={9}>
              <TextField
                id="textPrompt"
                label="User Prompt:"
                placeholder="Type your query here"
                multiline
                maxRows={8}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                disabled={isWating}
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  "@media (hover: none)": {
                    backgroundColor: "rgb(232, 241, 250)",
                  },
                }}
              />
            </Grid>
            <Grid item sm={5} md={5} lg={4} xl={3}>
              {isWating ? (
                <Box>
                  <CircularProgress
                    sx={{
                      ml: { xs: 1, sm: 2, md: 3, lg: 4 },
                      mr: { xs: 1, sm: 2, md: 3, lg: 4 },
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      background: "darkgreen",
                      color: "#fbf8e7",
                      border: "1px solid #272727",
                      fontWeight: 550,

                      "&:hover": {
                        background: "darkgreen",
                        color: "white",
                      },
                    }}
                    endIcon={<SendIcon />}
                    size="small"
                    onClick={handleSendClick}
                  >
                    Send
                  </Button>
                  <Button onClick={handleShare}>
                    <Tooltip title="Share" arrow>
                      <IosShareIcon
                        sx={{ color: "#966994", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Button>
                </>
              )}
            </Grid>
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={style}
              spacing={1}
            >
              <Grid item sm={6} md={9} lg={9} xl={9} sx={{ width: "70%" }}>
                <TextField
                  fullWidth
                  label="Copy as a link"
                  size="small"
                  value={copyLink}
                />
              </Grid>
              <Grid item sm={1} md={1} lg={1} xl={1}>
                {isCopied ? (
                  <TaskIcon sx={{ color: "green" }}></TaskIcon>
                ) : (
                  <Tooltip title="Copy">
                    <ContentCopyIcon
                      onClick={handleCopyLink}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </Grid>
              <Grid item sm={1} md={1} lg={1} xl={1}>
                <OpenInNewIcon
                  onClick={handleCopyClick}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          </Modal>

          <Modal
            sx={{ maxHeight: 900, overflowY: "auto" }}
            open={openEmailContent}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start  "
              sx={styleEmail}
              xl={8}
            >
              <Grid item sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    p: 2,
                    background: "darkgreen",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: "whitesmoke",
                    }}
                    variant="body1"
                  >
                    {" "}
                    Configure Email
                  </Typography>
                  {emailStatus ? (
                    <Alert variant="filled">Email sent Successfully</Alert>
                  ) : (
                    <></>
                  )}
                  {emailError ? (
                    <Alert variant="filled" severity="error">
                      Enter valid email ID
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Divider
                sx={{ width: "100%", borderBottom: "2px solid darkgrey" }}
              />
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ ml: 2, width: "100%", mt: 2 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      color: "black ",

                      mr: 2,
                      fontWeight: 600,
                      color: "darkslategrey",
                    }}
                    variant="caption"
                  >
                    Add a Recepients
                  </Typography>

                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={toEmail}
                    onChange={handleToEmailChange}
                    size="small"
                    sx={{ minWidth: "250px" }}
                  >
                    <MenuItem value="dinesh@bossify.ai">
                      dinesh@bossify.ai
                    </MenuItem>
                    <MenuItem value="Rich@bossify.ai">
                      Rich@bossify.ai{" "}
                    </MenuItem>
                    <MenuItem value="mathan@bossify.ai">
                      mathan@bossify.ai
                    </MenuItem>
                    <MenuItem value="diwakar@bossify.ai">
                      diwakar@bossify.ai
                    </MenuItem>
                    <MenuItem value="suganya@bossify.ai">
                      suganya@bossify.ai
                    </MenuItem>
                    <MenuItem value="kc@kcdonovan.com">
                      kc@kcdonovan.com
                    </MenuItem>
                    <MenuItem value="kc@bossify.ai">kc@bossify.ai</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ width: "100%", ml: 2, mr: 2, mb: 1 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "black ",
                        fontWeight: 600,
                        color: "darkslategrey",
                      }}
                      variant="caption"
                    >
                      {" "}
                      Email Content
                    </Typography>
                    {isCopied ? (
                      <TaskIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: 18,
                          ml: 1,
                          mt: 2,
                          color: "green",
                        }}
                      ></TaskIcon>
                    ) : (
                      <Tooltip title="Copy Email Content" arrow>
                        <ContentCopyIcon
                          onClick={handleCopyEmailContent}
                          sx={{
                            cursor: "pointer",
                            fontSize: 15,
                            ml: 1,
                            mt: 2,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sm={12}
                md={12}
                lg={12}
                xl={12}
                item
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "20px",
                  overflowY: "scroll",
                  maxHeight: 400,
                  minWidth: "97%",
                  ml: 2,
                  mb: 1,
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "black ",
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: emailContent,
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ ml: 2, width: "100%", p: 2 }}
              >
                <Button
                  variant="contained"
                  sx={{
                    background: "#794b77",
                    "&:hover": {
                      background: "#794b77",
                      color: "white",
                    },
                  }}
                  size="small"
                  onClick={() => handleSendEmail(emailContent)}
                  endIcon={<SendIcon />}
                  disabled={!toEmail.trim()}
                >
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      </Grid>
      <div ref={chatEndRef} />
    </>
  );
}

export default ChatComponent;

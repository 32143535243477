import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AccountCircle from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import * as UserService from "../../services/UserServices";

export default function UserProfile(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [username, setUsername] = React.useState("");
  useEffect(() => {
    setUsername(localStorage.getItem("username"));
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    if (event.currentTarget.id === "menuLogout") {
      UserService.logout();
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 0, pr: 2 }}>
        {/* <Tooltip title="Show Notifications">
           <IconButton
            size="large"
            // aria-label="show 17 new notifications"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <Badge badgeContent={3} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> 
        </Tooltip> */}
        <Tooltip title={"Logged in as: " + username}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, fontSize: 12 }}>
            {/* src = assign image source base64 data for profile picture */}
            <Avatar
              alt={username}
              src={username}
              sx={{
                background: "#966994",
                border: "2px solid darkslategrey",
              }}
            />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Typography
            variant="subtitle2"
            sx={{ pl: 2, pt: 1, pb: 1 }}
            color="steelblue"
          >
            Logged in as: <Box component={"span"}>{username}</Box>
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <MenuItem key="UserProfile" onClick={handleCloseUserMenu}>
            <AccountCircle />
            <Typography sx={{ ml: 1 }}>Profile Settings</Typography>
          </MenuItem>
          {/* <Divider /> */}
          <MenuItem
            id="menuLogout"
            name="menuLogout"
            key="menuLogout"
            onClick={handleCloseUserMenu}
          >
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>Logout</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </React.Fragment>
  );
}

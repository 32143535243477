import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ChatComponent from "./components/chat/ChatComponent";
import MainLayout from "./components/common/MainLayout";
import LoginLayout from "./components/layout/LoginLayout";
import Login from "./components/login/LoginComponent";
import UserGuide from "./components/common/UserGuide";
import ManageDocuments from "./components/configuration/ManageDocuments";
import ShareComponent from "./components/History/ShareHistory";
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<ChatComponent />} />
        <Route path="/manage" element={<ManageDocuments />} />
      </Route>
      <Route path="help" element={<UserGuide />} />
      <Route path="share/:urlSessionId/" element={<ShareComponent />} />
      <Route path="share/:urlSessionId/:chatId" element={<ShareComponent />} />

      <Route path="login" element={<Login />} />
    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

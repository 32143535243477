export const fetchDocumentType = async (accessToken) => {
  try {
    // Send user message to the backend

    const response = await fetch(
      "https://miraserver.quantino.in/mira/api/document_types/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (!response.ok) {
      //navigate("/");
      console.error("Error code:", response.status);
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    // Handle error, e.g., show an error message to the user
  }
};

export const fetchCategoryType = async (index) => {
  // Make API call based on the index
  const accessToken = localStorage.getItem("accessToken");
  let url =
    "https://miraserver.quantino.in/mira/api/document_type/" +
    index +
    "/categories";
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (!response.ok) {
      //navigate("/");
      console.error("Error code:", response.status);
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    return responseData;
    //setCatagoryType(responseData["categories"]);
    //setCatagoryId(index);
    // Toggle accordion expansion state

    // Handle the response data as needed
  } catch (error) {
    console.error("Error:", error.message);
    // Handle error, e.g., show an error message to the user
  }
};

export const fetchQuestionbyCategory = async (item, catagoryId) => {
  // Make API call based on the index
  console.log("item : ", item.id);
  console.log("catagoryId : ", catagoryId);

  const accessToken = localStorage.getItem("accessToken");

  let url =
    "https://miraserver.quantino.in/mira/api/document_type/" +
    catagoryId +
    "/category/" +
    item.id +
    "/questions/";
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    // Handle error, e.g., show an error message to the user
  }
};

import React, { useState, useEffect, useRef } from "react";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MiraLogo from "../../assets/MiraLogo.png";
import Logo from "../../assets/logo-2-white-2.png";
import PostAddIcon from "@mui/icons-material/PostAdd";
import HistoryIcon from "@mui/icons-material/History";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TuneIcon from "@mui/icons-material/Tune";
import LogoutIcon from "@mui/icons-material/Logout";
import AssistantIcon from "@mui/icons-material/Assistant";
import PersonIcon from "@mui/icons-material/Person";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DynamicGraph from "../Graph/DynamicGraph";

import { useParams } from "react-router-dom";

const ShareHistoryExport = () => {
  const { urlSessionId } = useParams();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState([]);

  const [username, setUsername] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let url = "";
      console.log("Chat : ", chatId);
      console.log("urlSessionId : ", urlSessionId);
      try {
        if (chatId !== null && chatId !== undefined) {
          url =
            "https://miraserver.quantino.in/mira/api/user-chat-sessions/" +
            urlSessionId +
            "/" +
            chatId +
            "/";
        } else if (chatId === undefined) {
          url =
            "https://miraserver.quantino.in/mira/api/user-chat-sessions/" +
            urlSessionId +
            "/";
        }

        console.log("URL : ", url);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();
        let tempMessages = [];

        // Map over selectedHistory and create new message objects
        const updatedMessages = responseData.map((historyItem) => {
          const promptResponseDict = JSON.parse(
            historyItem.prompt_response_dict
          );
          setUsername(historyItem.username);
          let userMessage = {
            sender: "user",
            graphInput: promptResponseDict.graph_input,
            options: promptResponseDict.options,
            text: promptResponseDict.prompt,
          };
          let miraMessage = {
            sender: "mira",
            graphInput: promptResponseDict.graph_input,
            options: promptResponseDict.options,
            text: promptResponseDict.answer,
          };
          tempMessages.push(userMessage);
          tempMessages.push(miraMessage);
        });

        // Update messages state with selected history
        setMessages(tempMessages);
        console.log("Messages : ", messages);
      } catch (error) {
        console.error("Error:", error);
        // Handle error, e.g., show an error message to the user
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const accumulatedResponses = messages.map(
      (message) => message.prompt_response_dict
    );
    setResponse(accumulatedResponses);
    console.log("Set response : ", response);
  }, [messages]);

  useEffect(() => {
    console.log("urlSessionId: ", urlSessionId);
  }, [urlSessionId]);

  const theme = createTheme({
    palette: {
      mode: "light",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            //background: '#075b74',
            background: "#f7f7f7",
            color: "#024f4f",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar>
              <img
                src={MiraLogo}
                alt="logo"
                style={{ width: 50, height: "auto", marginRight: 10 }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }} noWrap>
                AI Chief of Staff
              </Typography>
            </Toolbar>
          </Grid>
        </AppBar>
      </Box>

      <Toolbar />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {messages.map((message, index) => (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            key={index}
            sx={{
              padding: 1,
            }}
            spacing={1}
          >
            <Grid item>
              <ListItemIcon sx={{ minWidth: 0 }}>
                {message.sender === "user" ? (
                  <PersonIcon />
                ) : (
                  <img
                    src={MiraLogo}
                    alt="logo"
                    style={{ width: 20, height: "auto" }}
                  />
                )}
              </ListItemIcon>
            </Grid>
            <Grid item>
              {" "}
              <Typography
                sx={{ fontWeight: "bold", color: "darkslategrey" }}
                noWrap
              >
                {message.sender === "user" ? username : "Mira:"}
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={7} md={7} lg={7} xl={9}>
                  {message.sender === "user"
                    ? null
                    : message.graphInput && (
                        <DynamicGraph options={message.options} />
                      )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              {" "}
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  marginLeft: "40px",
                  color: "black ",
                  background:
                    message.sender === "user" ? "whitesmoke" : "whitesmoke",
                  border: "1px solid lightgrey",
                  p: 2,
                  borderRadius: 4,
                }}
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: message.text,
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

export default ShareHistoryExport;
